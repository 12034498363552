import React from "react";
import ReactPlayer from "react-player";

import styles from "./App.module.scss";

const videoUrls = [
  "https://youtu.be/EEXpzhJtlfc",
  "https://youtu.be/mahVHpCqoX4",
  "https://youtu.be/YNAqM6ZIy84",
];

const renderVideos = () => {
  return videoUrls.map((video, i) => (
    <article className={styles.video} key={i}>
      <ReactPlayer url={video} width="auto" height="auto" />
    </article>
  ));
};

function App() {
  return (
    <div className={styles.app}>
      <header className={styles.appHeader}>
        <h1 className={styles.srOnly}>Basic Television</h1>
        <img
          src="./BasicTelevisionLogo.png"
          alt="Basic Television Logo"
          className={styles.btvIcon}
        />
      </header>
      <main>
        <section className={styles.titles}>
          <h2>ONE</h2>
          <h3>The debut release.</h3>
        </section>
        <section className={styles.videoContent}>
          <div className={styles.videos}>{renderVideos()}</div>

          <a href="https://www.youtube.com/channel/UCoLCWWyRz96Joco4DUwE1Wg">
            Subscribe on YouTube
          </a>
        </section>
        <section className={styles.releaseInfo}>
          <h3>Listen now:</h3>
          <div className={styles.release}>
            <div className={styles.cover}>
              <a
                href="https://open.spotify.com/album/4iVEudHK8ZIuA1NqzmoytS"
                target="_blank"
                rel="nofollow noreferrer noopener"
              >
                <img
                  src="./ONE_cover.png"
                  alt="ONE - cover artwork"
                  width="250"
                  height="250"
                />
              </a>
            </div>
            <div className={styles.listen}>
              <ul>
                <li>
                  <a
                    href="https://open.spotify.com/album/4iVEudHK8ZIuA1NqzmoytS"
                    target="_blank"
                    rel="nofollow noreferrer noopener"
                  >
                    <img
                      src="./spotify.png"
                      alt="Listen to 'ONE' on Spotify"
                      width="240"
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="https://basictelevision.bandcamp.com/album/one"
                    target="_blank"
                    rel="nofollow noreferrer noopener"
                  >
                    <img
                      src="./bandcamp.png"
                      width="240"
                      alt="Get 'ONE' on Bandcamp"
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </section>

        <section>
          <p>___Stay up to date___</p>
          <ul className={styles.socialLinks}>
            <li>
              <a href="https://www.instagram.com/BasicTVofficial">
                [IG] @BasicTVofficial
              </a>
            </li>
            {/* <li><a href="https://www.soundcloud.com/basictelevision">[SOUNDCLOUD] @basictelevision</a></li> */}
            <li>
              <a href="https://www.youtube.com/channel/UCoLCWWyRz96Joco4DUwE1Wg">
                [YT] Basic Television
              </a>
            </li>
            <li>
              <a href="https://www.twitter.com/BasicTVofficial">
                [TW] @BasicTVofficial
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/basictelevision">
                [FB] @basictelevision
              </a>
            </li>
          </ul>
        </section>
      </main>
    </div>
  );
}

export default App;
